import "./App.scss";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Pagination from "react-bootstrap/Pagination";
import Modal from "react-bootstrap/Modal";

import axios from "axios";

import moment from "moment";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import InputGroup from "react-bootstrap/InputGroup";

import React, { useState, useEffect, useReducer, useCallback } from "react";

import { useForm } from "react-hook-form";

function App() {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    required,
    formState: { errors },
  } = useForm({ defaultValues: { id: "22-" } });

  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);

  const [showModal, setShowModal] = useState(false);
  const [showAddNewModal, setAddNewModal] = useState(false);
  const [showAddNewSubmitModal, setAddNewSubmitModal] = useState(false);
  const [showAddNewWithdrawalModal, setAddNewWithdrawalModal] = useState(false);
  const [bulletinAction, setBulletinAction] = useState("new");

  const [showBulletinList, setShowBulletinList] = useState(true);

  const [recentBulletins, setRecentBulletins] = useState([]);

  const [newBulletin, setNewBulletin] = useState({});
  const [mode, setMode] = useState("");

  const OnBulletinSubmit = (data) => {
    setMode("submitting");

    fetch("https://arc-bulletins.arccorp.workers.dev", {
      method: "POST",
      referrerPolicy: "no-referrer",
      body: JSON.stringify(data), // body data type must match "Content-Type" header
      headers: {
        "content-type": "application/json",
      },
    })
      .then(function(response) {
        //updateBulletinList();
        console.log(response);
        if (response.status === 200) {
          console.log("-- Bulletin Successfully Added --");
          console.log(data);
          setAddNewModal(false);
          setAddNewSubmitModal(false);
          setAddNewWithdrawalModal(false);
          setMode("");
          loadBulletinList();
          reset();
          return true;
        }
      })
      .catch((err) => {
        console.log("error");
        alert("Something went wrong when adding Bulletin. Please try again.");
        return false;
      });

    // axios({
    //   method: "post",
    //   headers: {
    //     "content-type": "application/json",
    //   },
    //   url: "https://arc-bulletins.arccorp.workers.dev",
    //   data: data,
    // })
    //   .then(function(response) {
    //     //updateBulletinList();
    //     console.log(response);
    //     if (response.status === 200) {
    //       console.log("-- Bulletin Successfully Added --");
    //       console.log(data);
    //       setAddNewModal(false);
    //       setAddNewSubmitModal(false);
    //       setAddNewWithdrawalModal(false);
    //       setMode("");
    //       loadBulletinList();
    //       reset();
    //       return true;
    //     }
    //   })
    //   .catch((err) => {
    //     console.log("error");
    //     alert("Something went wrong when adding Bulletin. Please try again.");
    //     return false;
    //   });
  };

  const editDefault = (key) => {
    axios({
      method: "get",
      headers: {
        "content-type": "application/json",
        // "access-control-allow-origin": "*",
      },
      url:
        "https://arc-bulletins.arccorp.workers.dev/single?key=bulletin-" + key,
    }).then(function(response) {
      setMode("Editing");
      console.log(response.data);
      reset(response.data);
      setAddNewModal(true);
    });
  };

  const editSubmit = (key) => {
    axios({
      method: "get",
      headers: {
        "content-type": "application/json",
        // "access-control-allow-origin": "*",
      },
      url:
        "https://arc-bulletins.arccorp.workers.dev/single?key=bulletin-" + key,
    }).then(function(response) {
      setMode("Editing");
      console.log(response.data);
      reset(response.data);
      setAddNewSubmitModal(true);
    });
  };

  const handleClose = () => {
    console.log("modal close");
    setAddNewModal(false);
    setAddNewSubmitModal(false);
    setAddNewWithdrawalModal(false);
    setMode("");
    reset();
  };

  const handleShow = () => setAddNewModal(true);

  const loadBulletinList = () => {
    setShowBulletinList(false);

    var tempRecentBulletins = [];
    setRecentBulletins(tempRecentBulletins);

    axios({
      method: "get",
      headers: {
        "content-type": "application/json",
        // "access-control-allow-origin": "*",
      },
      url: "https://arc-bulletins.arccorp.workers.dev/list",
    }).then(function(response) {
      var keys = response.data.keys;

      for (let i = 0; i < keys.length; i++) {
        const key = keys[i].name;
        console.log(key);

        if (keys.length < recentBulletins.length) {
          tempRecentBulletins = recentBulletins;
        } else {
          tempRecentBulletins = [];
        }

        axios({
          method: "get",
          headers: {
            "content-type": "application/json",
            // "access-control-allow-origin": "*",
          },
          url: "https://arc-bulletins.arccorp.workers.dev/single?key=" + key,
        }).then(function(response) {
          tempRecentBulletins.push(response.data);
          setRecentBulletins(
            tempRecentBulletins.sort(function(a, b) {
              var keyA = new Date(a.date),
                keyB = new Date(b.date);
              // Compare the 2 dates
              if (keyA < keyB) return 1;
              if (keyA > keyB) return -1;
              return 0;
            })
          );

          //console.log(recentBulletins);
          forceUpdate();
        });
      }
    });
  };

  return (
    <div>
      <div
        className="jumbo-container bg-color-teal"
        style={{ background: "#189bb0" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="bulletin-jumbo">
                <div className="bulletin-title-container d-flex align-items-center">
                  <img
                    src="https://www2.arccorp.com/globalassets/arc-logos/corporate-logos/arc-logo-m-white.png"
                    alt="ARC"
                    className="me-2"
                    style={{ maxWidth: "90px" }}
                  />
                  <h2 className="bulletin-title mb-0">Carrier Bulletins</h2>
                </div>

                <p className="bulletin-subtitle">
                  This application is used to add and edit carrier bulletins to
                  deploy to the front-end application.
                </p>

                {/* <div className="mb-3">
                  <a
                    onClick={() => setAddNewModal(true)}
                    className="btn btn-light"
                    href="#"
                  >
                    Add New Bulletin
                  </a>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="container"
        //style={{ marginTop: "-120px", marginBottom: "60px" }}
      >
        <div className="row">
          <div className="col-lg-12">
            <div className="arc-bulletin-list">
              <div className="row">
                <div className="col-lg-12">
                  <h2 className="bulletin-sub">Add Bulletins</h2>
                  <p>
                    To add a new bulletin, choose the type you want to add and
                    fill in the corresponding data fields. Once completed, click
                    submit and the bulletin will appear their respective list.
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4">
                  <div
                    className="bulletin-box-link"
                    onClick={() => setAddNewModal(true)}
                  >
                    Add Default Bulletin <i className="fas fa-plus-circle"></i>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div
                    className="bulletin-box-link"
                    onClick={() => setAddNewSubmitModal(true)}
                  >
                    Add Submit Bulletin <i className="fas fa-plus-circle"></i>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div
                    className="bulletin-box-link text-muted"
                    //onClick={() => setAddNewWithdrawalModal(true)}
                  >
                    Add Termination Bulletin{" "}
                    <i className="fas fa-plus-circle"></i>
                    <div className="coming-soon">Coming Soon</div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div
                    className="bulletin-box-link text-muted"
                    //onClick={() => setAddNewWithdrawalModal(true)}
                  >
                    Add Reinstatement Bulletin{" "}
                    <i className="fas fa-plus-circle"></i>
                    <div className="coming-soon">Coming Soon</div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div
                    className="bulletin-box-link text-muted"
                    //onClick={() => setAddNewWithdrawalModal(true)}
                    style={{ fontSize: "16px", lineHeight: "1.1" }}
                  >
                    Add Post Termination Uncollectable Debt Bulletin{" "}
                    <i className="fas fa-plus-circle"></i>
                    <div className="coming-soon">Coming Soon</div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <h2 className="bulletin-sub mt-5">Edit Bulletins</h2>
                  <p>
                    Click the button below to load the list of bulletins. To
                    edit submitted bulletins, click the edit button next to it's
                    respective entry. It may take a min or two for recently
                    submitted bulletins to show up on the list. Click the
                    refresh buttont after waiting to see the updated list.{" "}
                    <strong>
                      Note: Please wait up at least five minutes and click the
                      refresh list button before resubmitting a bulletin.{" "}
                    </strong>
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4">
                  <div
                    className={
                      showBulletinList ? "bulletin-box-link" : "d-none"
                    }
                    style={{ textAlign: "center" }}
                    onClick={() => loadBulletinList()}
                  >
                    Click To Load Bulletins
                    <i className="fas fa-file-download"></i>{" "}
                  </div>
                </div>
              </div>
              <div className={showBulletinList ? "d-none" : "row"}>
                <div className="col-lg-12">
                  <div className="row align-items-center my-4">
                    {/* <div className="col-lg-1">Filter:</div>
                    <div className="col-lg-2">
                      <Form.Select
                        size="sm"
                        aria-label="Default select example"
                      >
                        <option>Type</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </Form.Select>
                    </div>
                    <div className="col-lg-1">Sort:</div>
                    <div className="col-lg-2">
                      <Form.Select
                        size="sm"
                        aria-label="Default select example"
                      >
                        <option>Date</option>
                        <option value="1">Type</option>
                        <option value="2">DBA Name</option>
                      </Form.Select>
                    </div> */}
                    <div className="col-lg-2">
                      <button
                        className="btn btn-primary"
                        onClick={() => loadBulletinList()}
                      >
                        <i className="fas fa-sync me-2"></i>
                        Click to Refresh List
                      </button>
                    </div>
                  </div>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Date</th>
                        <th>DBA Name</th>
                        <th>Type</th>
                        <th>Edit</th>
                      </tr>
                    </thead>
                    <tbody>
                      {recentBulletins.length > 0 &&
                        recentBulletins.map((data, i) => {
                          return (
                            <tr key={i}>
                              <td>{data.id}</td>
                              <td>{data.date}</td>
                              <td>{data.dba_name}</td>
                              <td>{data.action}</td>

                              <td>
                                {data.action.indexOf("Default") > -1 && (
                                  <Button
                                    size="sm"
                                    onClick={() => editDefault(data.id)}
                                  >
                                    Edit
                                  </Button>
                                )}
                                {data.action.indexOf("Submit") > -1 && (
                                  <Button
                                    size="sm"
                                    onClick={() => editSubmit(data.id)}
                                  >
                                    Edit
                                  </Button>
                                )}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        dialogClassName="modal-70w"
        show={showModal}
        onHide={() => setShowModal(false)}
      >
        <Modal.Body>
          <div className="loading">
            <div className="loading-icon">
              <i className="fas fa-circle-notch fa-spin"></i>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={showAddNewModal}
        dialogClassName="modal-80w"
        onHide={() => handleClose()}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {mode === "Editing" ? "Edit " : "Add New "}Default Bulletin
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(OnBulletinSubmit)}>
            <div className="row">
              <div className="col-lg-2">
                <Form.Group>
                  <Form.Label>Bulletin Number</Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      size="sm"
                      defaultValue="22-"
                      placeholder="22-xxxx"
                      {...register("id")}
                    />
                  </InputGroup>
                </Form.Group>
              </div>
              <div className="col-lg-2">
                <Form.Group className="mb-3" controlId="exampleForm.date">
                  <Form.Label>Date</Form.Label>
                  <Form.Control
                    size="sm"
                    type="date"
                    defaultValue={new Date().toISOString().substr(0, 10)}
                    {...register("date")}
                  />
                </Form.Group>
              </div>
            </div>

            <hr />

            <div className="row">
              <div className="col-lg-2">
                <Form.Group className="mb-3" controlId="exampleForm.date">
                  <Form.Label>Violation Section</Form.Label>
                  <Form.Select
                    {...register("violation_section")}
                    size="sm"
                    aria-label="Default select example"
                  >
                    <option value="">---Choose---</option>
                    <option value="9">9</option>
                    <option value="13">13</option>
                    <option value="15">15</option>
                    <option value="33">33</option>
                    <option value="34">34</option>
                    <option value="None">None</option>
                  </Form.Select>
                </Form.Group>
              </div>
              <div className="col-lg-4">
                <Form.Group className="mb-3" controlId="exampleForm.date">
                  <Form.Label>Violation Type</Form.Label>
                  <Form.Select
                    size="sm"
                    aria-label="Default select example"
                    {...register("violation_type")}
                  >
                    <option value="">---Choose---</option>
                    <option value="Fraud – Section 9">Fraud – Section 9</option>
                    <option value="TAA Complaint - Section 13">
                      TAA Complaint - Section 13
                    </option>
                    <option value="Abandonment – Section 15">
                      Abandonment – Section 15
                    </option>
                    <option value="RD/TRS/MRS – Section 33">
                      RD/TRS/MRS – Section 33
                    </option>
                    <option value="Additional Operating Requirements – Section 34 ">
                      Additional Operating Requirements – Section 34{" "}
                    </option>
                    <option value="None">None</option>
                  </Form.Select>
                </Form.Group>
              </div>
              <div className="col-lg-3">
                <Form.Group className="mb-3" controlId="exampleForm.date">
                  <Form.Label>Agency Status</Form.Label>
                  <Form.Select
                    size="sm"
                    aria-label="Default select example"
                    {...register("agency_status")}
                  >
                    <option value="">---Choose---</option>
                    <option value="accredited">Accredited</option>
                    <option value="terminated">Terminated</option>
                  </Form.Select>
                </Form.Group>
              </div>
              <div className="col-lg-3">
                <Form.Group className="mb-3" controlId="exampleForm.date">
                  <Form.Label>Action</Form.Label>
                  <Form.Select
                    size="sm"
                    aria-label="Default select example"
                    {...register("action", { required: true })}
                    required
                  >
                    <option value="">---Choose---</option>
                    <option value="Default">Default</option>
                    <option value="Default-Withdrawal">
                      Default-Withdrawal
                    </option>
                    <option value="Default-Extension">Default-Extension</option>
                    <option value="Additional Operating Requirements">
                      Additional Operating Requirements
                    </option>
                  </Form.Select>
                </Form.Group>
              </div>
            </div>

            <hr />

            <div className="row">
              <div className="col-lg-4">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Home Office ACN </Form.Label>
                  <Form.Control
                    {...register("home_office_acn")}
                    size="sm"
                    type="text"
                  />
                </Form.Group>

                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Branch/STP ACN </Form.Label>
                  <Form.Control
                    size="sm"
                    as="textarea"
                    rows={7}
                    {...register("branch_stp_acn")}
                  />
                </Form.Group>
              </div>
              <div className="col-lg-8">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Legal name </Form.Label>
                  <Form.Control
                    size="sm"
                    type="text"
                    {...register("legal_name")}
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>DBA Name </Form.Label>
                  <Form.Control
                    size="sm"
                    type="text"
                    {...register("dba_name")}
                  />
                </Form.Group>

                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Address </Form.Label>
                  <Form.Control
                    size="sm"
                    as="textarea"
                    rows={3}
                    {...register("address")}
                  />
                </Form.Group>
              </div>
            </div>

            <hr />

            <div className="row">
              <div className="col-lg-3">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Reference Bulletin Number </Form.Label>
                  <Form.Control
                    size="sm"
                    type="text"
                    placeholder="Previous Number"
                    {...register("reference_bulletin_number")}
                  />
                </Form.Group>
              </div>
              <div className="col-lg-3">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Reference Bulletin Date </Form.Label>
                  <Form.Control
                    size="sm"
                    type="date"
                    placeholder="Previous Date"
                    {...register("reference_bulletin_date")}
                  />
                </Form.Group>
              </div>
              <div className="col-lg-3">
                <Form.Group className="mb-3" controlId="exampleForm.date">
                  <Form.Label>Financial Instrument Type</Form.Label>
                  <Form.Select
                    size="sm"
                    aria-label="Default select example"
                    {...register("Financial Instrument Type")}
                  >
                    <option value="">---Choose---</option>
                    <option value="Bond">Bond</option>
                    <option value="Letter of Credit">Letter of Credit</option>
                    <option value="Cash Security Deposit">
                      Cash Security Deposit
                    </option>
                  </Form.Select>
                </Form.Group>
              </div>
              <div className="col-lg-3">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Financial Instrument Amount </Form.Label>
                  <Form.Control
                    size="sm"
                    type="text"
                    placeholder="$10,000"
                    {...register("financial_instrument_amount")}
                  />
                </Form.Group>
              </div>
              <div className="col-lg-4"></div>
            </div>

            <hr />

            <div className="row">
              <div className="col-lg-4">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Known Debt </Form.Label>
                  <Form.Control
                    size="sm"
                    type="text"
                    placeholder="$10,000"
                    {...register("known_debt")}
                  />
                </Form.Group>
              </div>
              <div className="col-lg-4">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Missing Sales Report </Form.Label>
                  <Form.Control
                    size="sm"
                    type="text"
                    {...register("missing_sales_report")}
                    //placeholder="Previous Date"
                  />
                </Form.Group>
              </div>
              <div className="col-lg-4">
                <Form.Group className="mb-3" controlId="exampleForm.date">
                  <Form.Label>Returned Drafts </Form.Label>
                  <Form.Control
                    size="sm"
                    as="textarea"
                    rows={3}
                    {...register("returned_drafts")}
                  />
                </Form.Group>
              </div>
            </div>

            <hr />

            <div className="row">
              <div className="col">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Pending Section 9 Termination Date </Form.Label>
                  <Form.Control
                    size="sm"
                    type="date"
                    {...register("pending_section_9_termination_date")}
                  />
                </Form.Group>
              </div>
              <div className="col">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Pending Section 13 Termination Date </Form.Label>
                  <Form.Control
                    size="sm"
                    type="date"
                    {...register("pending_section_13_termination_date")}
                  />
                </Form.Group>
              </div>
              <div className="col">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Pending Section 15 Termination Date </Form.Label>
                  <Form.Control
                    size="sm"
                    type="date"
                    {...register("pending_section_15_termination_date")}
                  />
                </Form.Group>
              </div>
              <div className="col">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Pending Section 33 Termination Date </Form.Label>
                  <Form.Control
                    size="sm"
                    type="date"
                    {...register("pending_section_33_termination_date")}
                  />
                </Form.Group>
              </div>
              <div className="col">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Pending Section 34 Termination Date </Form.Label>
                  <Form.Control
                    size="sm"
                    type="date"
                    {...register("pending_section_34_termination_date")}
                  />
                </Form.Group>
              </div>
            </div>

            <hr />

            <div className="row">
              <div className="col-lg-6">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>ARC Representative</Form.Label>
                  <Form.Control
                    size="sm"
                    type="text"
                    placeholder="Lisa Dunson"
                    {...register("arc_representative")}
                  />
                </Form.Group>
              </div>
              <div className="col-lg-6">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>ARC Contact Number</Form.Label>
                  <Form.Control
                    size="sm"
                    type="text"
                    placeholder="703-816-8075"
                    {...register("arc_contact_number")}
                  />
                </Form.Group>
              </div>
            </div>

            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Notes</Form.Label>
              <Form.Control
                size="sm"
                as="textarea"
                rows={3}
                {...register("notes")}
              />
            </Form.Group>

            <div className="row mt-5 ml-auto">
              <div className="col-lg-12">
                <Button variant="secondary" onClick={() => handleClose()}>
                  Close
                </Button>
                <Button
                  className="btn btn-primary"
                  type="submit"
                  variant="primary"
                  placeholder="Save Changes"
                >
                  {mode === "Editing" ? "Edit " : "Add New "}Bulletin
                </Button>
              </div>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      {/* addnewsubmitmodal - submit modal*/}
      <Modal
        show={showAddNewSubmitModal}
        dialogClassName="modal-80w"
        onHide={() => setAddNewSubmitModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {mode === "Editing" ? "Edit " : "Add New "}Submit Bulletin
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(OnBulletinSubmit)}>
            <div className="row">
              <div className="col-lg-2">
                <Form.Group>
                  <Form.Label>Bulletin Number</Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      size="sm"
                      defaultValue={newBulletin.id}
                      placeholder="22-xxxx"
                      {...register("id")}
                    />
                  </InputGroup>
                </Form.Group>
              </div>
              <div className="col-lg-2">
                <Form.Group className="mb-3" controlId="exampleForm.date">
                  <Form.Label>Date</Form.Label>
                  <Form.Control
                    size="sm"
                    type="date"
                    defaultValue={new Date().toISOString().substr(0, 10)}
                    {...register("date")}
                  />
                </Form.Group>
              </div>
            </div>

            <hr />

            <div className="row">
              <div className="col-lg-4">
                <Form.Group className="mb-3" controlId="exampleForm.date">
                  <Form.Label>Violation Type</Form.Label>
                  <Form.Select
                    size="sm"
                    aria-label="Default select example"
                    {...register("violation_type")}
                  >
                    <option value="">---Choose---</option>
                    <option value="VTC Reclassification">
                      VTC Reclassification
                    </option>
                    <option value="Financial Instrument Replacement">
                      Financial Instrument Replacement
                    </option>
                    <option value="Financial Instrument Change">
                      Financial Instrument Change
                    </option>
                  </Form.Select>
                </Form.Group>
              </div>
              <div className="col-lg-3">
                <Form.Group className="mb-3" controlId="exampleForm.date">
                  <Form.Label>Agency Status</Form.Label>
                  <Form.Select
                    size="sm"
                    aria-label="Default select example"
                    {...register("agency_status")}
                  >
                    <option value="">---Choose---</option>
                    <option value="accredited">Accredited</option>
                    <option value="terminated">Terminated</option>
                  </Form.Select>
                </Form.Group>
              </div>
              <div className="col-lg-3">
                <Form.Group className="mb-3" controlId="exampleForm.date">
                  <Form.Label>Action</Form.Label>
                  <Form.Select
                    size="sm"
                    aria-label="Default select example"
                    {...register("action", { required: true })}
                    required
                    defaultValue="Submit"
                  >
                    <option value="">---Choose---</option>
                    <option value="Submit">Submit</option>
                  </Form.Select>
                </Form.Group>
              </div>
            </div>

            <hr />

            <div className="row">
              <div className="col-lg-4">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Home Office ACN </Form.Label>
                  <Form.Control
                    {...register("home_office_acn")}
                    size="sm"
                    type="text"
                  />
                </Form.Group>

                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Branch/STP ACN </Form.Label>
                  <Form.Control
                    size="sm"
                    as="textarea"
                    rows={7}
                    {...register("branch_stp_acn")}
                  />
                </Form.Group>
              </div>
              <div className="col-lg-8">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Legal name </Form.Label>
                  <Form.Control
                    size="sm"
                    type="text"
                    {...register("legal_name")}
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>DBA Name </Form.Label>
                  <Form.Control
                    size="sm"
                    type="text"
                    {...register("dba_name")}
                  />
                </Form.Group>

                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Address </Form.Label>
                  <Form.Control
                    size="sm"
                    as="textarea"
                    rows={3}
                    {...register("address")}
                  />
                </Form.Group>
              </div>
            </div>

            <hr />

            <div className="row">
              <div className="col-lg-3">
                <Form.Group className="mb-3" controlId="exampleForm.date">
                  <Form.Label>Financial Instrument Type</Form.Label>
                  <Form.Select
                    size="sm"
                    aria-label="Default select example"
                    {...register("Financial Instrument Type")}
                  >
                    <option value="">---Choose---</option>
                    <option value="Bond">Bond</option>
                    <option value="Letter of Credit">Letter of Credit</option>
                    <option value="Cash Security Deposit">
                      Cash Security Deposit
                    </option>
                  </Form.Select>
                </Form.Group>
              </div>
              <div className="col-lg-3">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Financial Instrument Amount </Form.Label>
                  <Form.Control
                    size="sm"
                    type="text"
                    placeholder="$10,000"
                    {...register("financial_instrument_amount")}
                  />
                </Form.Group>
              </div>
              <div className="col-lg-4"></div>
            </div>

            <hr />

            <div className="row">
              <div className="col-lg-4">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Known Debt </Form.Label>
                  <Form.Control
                    size="sm"
                    type="text"
                    placeholder="$10,000"
                    {...register("known_debt")}
                  />
                </Form.Group>
              </div>
              <div className="col-lg-4">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Missing Sales Report </Form.Label>
                  <Form.Control
                    size="sm"
                    type="text"
                    {...register("missing_sales_report")}
                    //placeholder="Previous Date"
                  />
                </Form.Group>
              </div>
              <div className="col-lg-4">
                <Form.Group className="mb-3" controlId="exampleForm.date">
                  <Form.Label>Returned Drafts </Form.Label>
                  <Form.Control
                    size="sm"
                    as="textarea"
                    rows={3}
                    {...register("returned_drafts")}
                  />
                </Form.Group>
              </div>
            </div>

            <hr />
            <div className="mb-3">
              <strong>
                <em>IF BEING REPLACED/CHANGED</em>
              </strong>
            </div>
            <div className="row">
              <div className="col">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Current Financial Instrument Type </Form.Label>
                  <Form.Select
                    size="sm"
                    aria-label="Current Financial Instrument Type"
                    {...register("current_financial_instrument_type")}
                  >
                    <option value="">---Choose---</option>
                    <option value="Bond">Bond</option>
                    <option value="Letter of Credit">Letter of Credit</option>
                    <option value="Cash Security Deposit">
                      Cash Security Deposit
                    </option>
                  </Form.Select>
                </Form.Group>
              </div>
              <div className="col">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Current Financial Instrument Amount </Form.Label>
                  <Form.Control
                    size="sm"
                    type="text"
                    placeholder="$10,000"
                    {...register("current_financial_instrument_amount")}
                  />
                </Form.Group>
              </div>
              <div className="col">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Replacement Financial Instrument Type</Form.Label>
                  <Form.Select
                    size="sm"
                    aria-label="Replacement Financial Instrument Type"
                    {...register("replacement_financial_instrument_type")}
                  >
                    <option value="">---Choose---</option>
                    <option value="Bond">Bond</option>
                    <option value="Letter of Credit">Letter of Credit</option>
                    <option value="Cash Security Deposit">
                      Cash Security Deposit
                    </option>
                  </Form.Select>
                </Form.Group>
              </div>
              <div className="col">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>
                    Replacement Financial Instrument Amount{" "}
                  </Form.Label>
                  <Form.Control
                    size="sm"
                    type="text"
                    placeholder="$10,000"
                    {...register("replacement_financial_instrument_amount")}
                  />
                </Form.Group>
              </div>
            </div>

            <hr />

            <div className="row">
              <div className="col-lg-6">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>ARC Representative</Form.Label>
                  <Form.Control
                    size="sm"
                    type="text"
                    placeholder="703-816-8075 "
                    {...register("arc_representative")}
                  />
                </Form.Group>
              </div>
              <div className="col-lg-6">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>ARC Contact Number</Form.Label>
                  <Form.Control
                    size="sm"
                    type="text"
                    placeholder="Lisa Dunson"
                    {...register("arc_contact_number")}
                  />
                </Form.Group>
              </div>
            </div>

            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Notes</Form.Label>
              <Form.Control
                size="sm"
                as="textarea"
                rows={3}
                {...register("notes")}
              />
            </Form.Group>

            <div className="row mt-5 ml-auto">
              <div className="col-lg-12">
                <Button variant="secondary" onClick={() => handleClose()}>
                  Close
                </Button>
                <Button
                  className="btn btn-primary"
                  type="submit"
                  variant="primary"
                  placeholder="Save Changes"
                >
                  {mode === "Editing" ? "Edit " : "Add New "}Bulletin
                </Button>
              </div>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      {/* addnewwithdrawalmodal - withdrawal modal*/}
      <Modal
        show={showAddNewWithdrawalModal}
        dialogClassName="modal-80w"
        onHide={() => setAddNewWithdrawalModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {mode === "Editing" ? "Edit " : "Add New "}Withdrawal Bulletin
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(OnBulletinSubmit)}>
            <div className="row">
              <div className="col-lg-2">
                <Form.Group>
                  <Form.Label>Bulletin Number</Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      size="sm"
                      defaultValue="22-"
                      placeholder="22-xxxx"
                      {...register("id")}
                    />
                  </InputGroup>
                </Form.Group>
              </div>
              <div className="col-lg-2">
                <Form.Group className="mb-3" controlId="exampleForm.date">
                  <Form.Label>Date</Form.Label>
                  <Form.Control
                    size="sm"
                    type="date"
                    defaultValue={new Date().toISOString().substr(0, 10)}
                    {...register("date")}
                  />
                </Form.Group>
              </div>
            </div>

            <hr />

            <div className="row">
              <div className="col-lg-2">
                <Form.Group className="mb-3" controlId="exampleForm.date">
                  <Form.Label>Violation Section</Form.Label>
                  <Form.Select
                    {...register("violation_section")}
                    size="sm"
                    aria-label="Default select example"
                  >
                    <option value="">---Choose---</option>
                    <option value="9">9</option>
                    <option value="13">13</option>
                    <option value="15">15</option>
                    <option value="33">33</option>
                    <option value="34">34</option>
                    <option value="None">None</option>
                  </Form.Select>
                </Form.Group>
              </div>
              <div className="col-lg-4">
                <Form.Group className="mb-3" controlId="exampleForm.date">
                  <Form.Label>Violation Type</Form.Label>
                  <Form.Select
                    size="sm"
                    aria-label="Default select example"
                    {...register("violation_type")}
                  >
                    <option value="">---Choose---</option>
                    <option value="Fraud – Section 9">Fraud – Section 9</option>
                    <option value="TAA Complaint - Section 13">
                      TAA Complaint - Section 13
                    </option>
                    <option value="Abandonment – Section 15">
                      Abandonment – Section 15
                    </option>
                    <option value="RD/TRS/MRS – Section 33">
                      RD/TRS/MRS – Section 33
                    </option>
                    <option value="Additional Operating Requirements – Section 34 ">
                      Additional Operating Requirements – Section 34{" "}
                    </option>
                    <option value="None">None</option>
                  </Form.Select>
                </Form.Group>
              </div>
              <div className="col-lg-3">
                <Form.Group className="mb-3" controlId="exampleForm.date">
                  <Form.Label>Agency Status</Form.Label>
                  <Form.Select
                    size="sm"
                    aria-label="Default select example"
                    {...register("agency_status")}
                  >
                    <option value="">---Choose---</option>
                    <option value="accredited">Accredited</option>
                    <option value="terminated">Terminated</option>
                  </Form.Select>
                </Form.Group>
              </div>
              <div className="col-lg-3">
                <Form.Group className="mb-3" controlId="exampleForm.date">
                  <Form.Label>Action</Form.Label>
                  <Form.Select
                    size="sm"
                    aria-label="Default select example"
                    {...register("action", { required: true })}
                    required
                  >
                    <option value="">---Choose---</option>
                    <option value="Default">Default</option>
                    <option value="Default-Withdrawal">
                      Default-Withdrawal
                    </option>
                    <option value="Default-Extension">Default-Extension</option>
                    <option value="Additional Operating Requirements">
                      Additional Operating Requirements
                    </option>
                  </Form.Select>
                </Form.Group>
              </div>
            </div>

            <hr />

            <div className="row">
              <div className="col-lg-4">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Home Office ACN </Form.Label>
                  <Form.Control
                    {...register("home_office_acn")}
                    size="sm"
                    type="text"
                  />
                </Form.Group>

                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Branch/STP ACN </Form.Label>
                  <Form.Control
                    size="sm"
                    as="textarea"
                    rows={7}
                    {...register("branch_stp_acn")}
                  />
                </Form.Group>
              </div>
              <div className="col-lg-8">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Legal name </Form.Label>
                  <Form.Control
                    size="sm"
                    type="text"
                    {...register("legal_name")}
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>DBA Name </Form.Label>
                  <Form.Control
                    size="sm"
                    type="text"
                    {...register("dba_name")}
                  />
                </Form.Group>

                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Address </Form.Label>
                  <Form.Control
                    size="sm"
                    as="textarea"
                    rows={3}
                    {...register("address")}
                  />
                </Form.Group>
              </div>
            </div>

            <hr />

            <div className="row">
              <div className="col-lg-3">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Reference Bulletin Number </Form.Label>
                  <Form.Control
                    size="sm"
                    type="text"
                    placeholder="Previous Number"
                    {...register("reference_bulletin_number")}
                  />
                </Form.Group>
              </div>
              <div className="col-lg-3">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Reference Bulletin Date </Form.Label>
                  <Form.Control
                    size="sm"
                    type="date"
                    placeholder="Previous Date"
                    {...register("reference_bulletin_date")}
                  />
                </Form.Group>
              </div>
              <div className="col-lg-3">
                <Form.Group className="mb-3" controlId="exampleForm.date">
                  <Form.Label>Financial Instrument Type</Form.Label>
                  <Form.Select
                    size="sm"
                    aria-label="Default select example"
                    {...register("Financial Instrument Type")}
                  >
                    <option value="">---Choose---</option>
                    <option value="Bond">Bond</option>
                    <option value="Letter of Credit">Letter of Credit</option>
                    <option value="Cash Security Deposit">
                      Cash Security Deposit
                    </option>
                  </Form.Select>
                </Form.Group>
              </div>
              <div className="col-lg-3">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Financial Instrument Amount </Form.Label>
                  <Form.Control
                    size="sm"
                    type="text"
                    placeholder="$10,000"
                    {...register("financial_instrument_amount")}
                  />
                </Form.Group>
              </div>
              <div className="col-lg-4"></div>
            </div>

            <hr />

            <div className="row">
              <div className="col-lg-4">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Known Debt </Form.Label>
                  <Form.Control
                    size="sm"
                    type="text"
                    placeholder="$10,000"
                    {...register("known_debt")}
                  />
                </Form.Group>
              </div>
              <div className="col-lg-4">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Missing Sales Report </Form.Label>
                  <Form.Control
                    size="sm"
                    type="text"
                    {...register("missing_sales_report")}
                    //placeholder="Previous Date"
                  />
                </Form.Group>
              </div>
              <div className="col-lg-4">
                <Form.Group className="mb-3" controlId="exampleForm.date">
                  <Form.Label>Returned Drafts </Form.Label>
                  <Form.Select
                    size="sm"
                    aria-label="Default select example"
                    {...register("returned_drafts")}
                  >
                    <option value="">---Choose---</option>
                    <option value="Bond">Bond</option>
                    <option value="Letter of Credit">Letter of Credit</option>
                    <option value="Cash Security Deposit">
                      Cash Security Deposit
                    </option>
                  </Form.Select>
                </Form.Group>
              </div>
            </div>

            <hr />

            <div className="row">
              <div className="col">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Pending Section 9 Termination Date </Form.Label>
                  <Form.Control
                    size="sm"
                    type="date"
                    {...register("pending_section_9_termination_date")}
                  />
                </Form.Group>
              </div>
              <div className="col">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Pending Section 13 Termination Date </Form.Label>
                  <Form.Control
                    size="sm"
                    type="date"
                    {...register("pending_section_13_termination_date")}
                  />
                </Form.Group>
              </div>
              <div className="col">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Pending Section 15 Termination Date </Form.Label>
                  <Form.Control
                    size="sm"
                    type="date"
                    {...register("pending_section_15_termination_date")}
                  />
                </Form.Group>
              </div>
              <div className="col">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Pending Section 33 Termination Date </Form.Label>
                  <Form.Control
                    size="sm"
                    type="date"
                    {...register("pending_section_33_termination_date")}
                  />
                </Form.Group>
              </div>
              <div className="col">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Pending Section 34 Termination Date </Form.Label>
                  <Form.Control
                    size="sm"
                    type="date"
                    {...register("pending_section_34_termination_date")}
                  />
                </Form.Group>
              </div>
            </div>

            <hr />

            <div className="row">
              <div className="col-lg-6">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>ARC Representative</Form.Label>
                  <Form.Control
                    size="sm"
                    type="text"
                    placeholder="703-816-8075 "
                    {...register("arc_representative")}
                  />
                </Form.Group>
              </div>
              <div className="col-lg-6">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>ARC Contact Number</Form.Label>
                  <Form.Control
                    size="sm"
                    type="text"
                    placeholder="Lisa Dunson"
                    {...register("arc_contact_number")}
                  />
                </Form.Group>
              </div>
            </div>

            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Notes</Form.Label>
              <Form.Control
                size="sm"
                as="textarea"
                rows={3}
                {...register("notes")}
              />
            </Form.Group>

            <div className="row mt-5 ml-auto">
              <div className="col-lg-12">
                <Button variant="secondary" onClick={() => handleClose()}>
                  Close
                </Button>
                <Button
                  className="btn btn-primary"
                  type="submit"
                  variant="primary"
                  placeholder="Save Changes"
                >
                  {mode === "Editing" ? "Edit " : "Add New "}Bulletin
                </Button>
              </div>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default App;
